<template>
  <div class="settings">
    <h4 class="fw-bold mb-4 d-flex page-title">
        <span class="mr-auto">
          {{ $trans('System settings') }}
        </span>
    </h4>
    <div class="card">
      <div class="card-body">
        <div class="tab-content p-0 mt-3">
          <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
            {{ alert.message }}
          </div>
          <button @click="save_settings" class="btn btn-primary">
            <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
            {{ $trans('Save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "System",
  data: function () {
    return {
      settings: {},
      process: false,
      alert: null,
      errors: {},
      tabActive: 'agent'
    }
  },
  methods: {
    get_settings: function () {
      axios({url: this.$root.$data.api_url + '/api/settings/system', params: {}, method: 'GET'})
          .then(resp => {
            this.settings = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    save_settings: function () {
      this.alert = null
      if (this.process == false) {
        this.process = true;
        let data = this.settings;
        axios({url: this.$root.$data.api_url + '/api/settings/system', data: data, method: 'PUT'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.alert = {
                  type: 'success',
                  message: resp.data.message
                }
                this.get_settings();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;

            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
  },
  created() {
    this.get_settings()
  },
  computed:{
    wallets:function (){
      return this.$root.global_settings.wallets
    },
    platform_options:function () {
      let platform_options =[]
      if( this.wallets){
        let wallets = this.wallets
        wallets.forEach((el)=>{
          let item = {
            id: el.key,
            text: el.name
          }
          platform_options.push(item)
        })
      }
      return  platform_options
    }
  }
}
</script>

<style scoped>

</style>